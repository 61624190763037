import React from 'react';

import { BlogPostList } from 'components/blog';
import { SEO } from 'components/ui';
import seo from 'data/seo.json';

export default function Blog() {
    return (
        <>
            <SEO
                title={seo.blogListTitle}
                description={seo.blogListDescription}
                ogTitle={seo.blogListOgTitle}
                ogDescription={seo.blogListOgDescription}
                ogImage={seo.blogListOgImage}
            />
            <BlogPostList />
        </>
    );
}
